import { Page, CaseStudyGrid } from "@dangerfarms/website-core";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";
import {
  Box,
  Section,
  Stack,
  Text,
  ContentBlock,
} from "@dangerfarms/ui-system";
import { graphql } from "gatsby";
import React from "react";

const ClientLogos = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.rhythm(4)};

  @media screen and (min-width: 450px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

// Disabling prop type checks for the usual reason here, see pages/index.js
// for a better explanation!
/* eslint-disable react/prop-types */
const CaseStudies = ({ data }) => {
  const pageContent = data.prismic.allCase_studies_lists.edges[0].node;
  const { description } = pageContent;
  const logos = data.prismic.allClient_logoss.edges[0].node.logos;
  const caseStudies = data.prismic.allCase_studys.edges.map(edge => edge.node);
  const heroHeading = RichText.asText(pageContent.hero_heading);
  const heroLede = RichText.asText(pageContent.hero_lede);
  const ourWorkHeading = RichText.asText(pageContent.our_work_heading);

  return (
    <Page description={description} title="Case Studies">
      <Box backgroundColor="bg" paddingX="gutter" paddingY={8}>
        <ContentBlock>
          <Stack>
            <Text as="h1" variant="epic">
              {heroHeading}
            </Text>
            <Text maxWidth="30em" variant="lede">
              {heroLede}
            </Text>
          </Stack>
        </ContentBlock>
      </Box>
      <Box backgroundColor="barelyGrey" paddingX="gutter" paddingY={6}>
        <ContentBlock>
          <ClientLogos>
            {logos.map(logo => (
              <Box
                key={logo.logo_image.alt}
                alignItems="center"
                boxDisplay="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <img
                  alt={logo.logo_image.alt}
                  src={logo.logo_image.url}
                  title={logo.logo_image.alt}
                />
              </Box>
            ))}
          </ClientLogos>
        </ContentBlock>
      </Box>
      <Section backgroundColor="paleGrey">
        <Stack align="stretch" space={3}>
          <Text variant="heading">{ourWorkHeading}</Text>
          <CaseStudyGrid caseStudies={caseStudies} />
        </Stack>
      </Section>
    </Page>
  );
};
/* eslint-enable */

export const query = graphql`
  query {
    prismic {
      allCase_studies_lists {
        edges {
          node {
            description
            hero_heading
            hero_lede
            our_work_heading
          }
        }
      }
      allClient_logoss {
        edges {
          node {
            title
            logos {
              logo_image
            }
          }
        }
      }
      allCase_studys(sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            ...CaseStudy
          }
        }
      }
    }
  }
`;

export default CaseStudies;
